import {
  FormEventHandler,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { TextField, StandardTextFieldProps, IconButton } from '@mui/material';
import InputMask, { Props as ReactInputMaskProps } from 'react-input-mask';
import { Control, Controller } from 'react-hook-form';
import { styles } from './styles';
import { twMerge } from 'tailwind-merge';
import { Typography } from '../Typography';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import { VibSpinner } from '@vibTheme/components/VibSpinner';

export type InputTextProps = Omit<StandardTextFieldProps, 'value'> & {
  name: string;
  control: Control<any>;
  mask?: ReactInputMaskProps['mask'];
  onChangeCallback?: InputTextProps['onChange'];
  onBlurCallback?: InputTextProps['onBlur'];
  editable?: boolean;
  editOnSubmit?: () => Promise<boolean>;
  formatDisplayValue?: (val: string) => string;
};

export const InputText = ({
  name,
  control,
  defaultValue,
  mask,
  onChangeCallback,
  onBlurCallback,
  editable,
  editOnSubmit,
  formatDisplayValue,
  ...restProps
}: InputTextProps) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue ?? ''}
      // Retira 'ref' do objeto 'field'
      // https://github.com/react-hook-form/react-hook-form/issues/9126#issuecomment-1370843816
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      render={({ field: { ref, ...field }, formState }) => {
        const handleChange: InputTextProps['onChange'] = (event) => {
          field.onChange(event);
          onChangeCallback?.(event);
        };

        const handleBlur: InputTextProps['onBlur'] = (event) => {
          field.onBlur();
          onBlurCallback?.(event);
        };

        if (!editable)
          return (
            <InputMask
              {...field}
              mask={mask ?? ''}
              maskChar=""
              disabled={restProps.disabled}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              {
                (() => (
                  <TextField
                    {...field}
                    {...restProps}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={twMerge(styles, restProps.className)}
                  />
                )) as unknown as ReactNode
              }
            </InputMask>
          );

        const [isEditing, setIsEditing] = useState(false);
        const [isLoading, setIsLoading] = useState(false);
        const inputRef = useRef<HTMLInputElement>(null);
        const toggleEditing = () => {
          setIsEditing((e) => !e);
        };
        const submit: FormEventHandler = async (e) => {
          e.preventDefault();

          setIsLoading(true);
          const success = await editOnSubmit?.();
          setIsLoading(false);
          success && toggleEditing();
        };
        useEffect(() => {
          isEditing && inputRef.current?.focus();
        }, [isEditing]);

        return isEditing ? (
          <form onSubmit={submit}>
            <InputMask
              {...field}
              mask={mask ?? ''}
              maskChar=""
              disabled={restProps.disabled}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              {
                (() => (
                  <TextField
                    {...field}
                    {...restProps}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={twMerge(
                      styles,
                      restProps.className,
                      'min-w-[100px] max-w-[400px]',
                    )}
                    InputProps={{
                      inputRef,
                      endAdornment: (
                        <IconButton
                          type="submit"
                          onClick={submit}
                          disabled={isLoading}
                        >
                          {isLoading ? (
                            <VibSpinner size={20} />
                          ) : (
                            <CheckIcon className="text-primary" />
                          )}
                        </IconButton>
                      ),
                    }}
                  />
                )) as unknown as ReactNode
              }
            </InputMask>
          </form>
        ) : (
          <div className="flex items-center justify-center">
            <Typography className="text-sm">
              {formatDisplayValue?.(field.value) || field.value}
            </Typography>
            <IconButton onClick={toggleEditing}>
              <EditIcon className="text-primary" fontSize="small" />
            </IconButton>
          </div>
        );
      }}
    />
  );
};

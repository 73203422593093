import { ApiResponse } from '@interfaces/api';
import { z } from 'zod';

export enum ReportOutputType {
  JSON = 'json',
  CSV = 'csv',
  XLSX = 'xlsx',
  PDF = 'pdf',
}

export type FindReportDto<T = object> = {
  filters?: T;
  pagination?: { start: number; end: number };
  outputType?: ReportOutputType;
};

export type ReportResponse<T> = ApiResponse<T> & {
  totals?: Record<string, any>;
};

export type ReportCSVResponse = {
  reportUrl: string;
};

export const baseSchema = z.object({
  botConfigIds: z
    .array(z.number())
    .nonempty('Você deve selecionar ao menos uma comunidade.'),
  startDate: z.date({
    errorMap: () => ({
      message: 'A data de início é obrigatória e deve ter um valor válido.',
    }),
  }),
  endDate: z.date({
    errorMap: () => ({
      message: 'A data de fim é obrigatória e deve ter um valor válido.',
    }),
  }),
});

export const validateReportEmail = z
  .string()
  .trim()
  .email('O formato do e-mail é inválido.')
  .or(z.literal(''));

export const validateReportId = z.coerce
  .number({ invalid_type_error: 'O ID deve ser um número.' })
  .optional()
  .transform((x) => x || undefined);

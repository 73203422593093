// Filtra nulls, undefineds, strings vazias
export const parseFilters = <T extends object>(fields: T) =>
  Object.fromEntries(
    Object.entries(fields).filter(
      (entry) =>
        entry[1] !== undefined &&
        entry[1] !== null &&
        entry[1].toString().trim() !== '' &&
        entry[1] !== -1,
    ),
  ) as T;

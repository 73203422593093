import { InputSelectProps } from './InputSelect';
import useGroupsContext from '@contexts/GroupsContext/hook';
import { Controller, useForm } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import {
  Chip,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  Modal,
  Select,
} from '@mui/material';
import { Typography } from '@vibTheme/components/_base/Typography';
import { InputText } from './InputText';
import SearchIcon from '@mui/icons-material/Search';
import VibDataGrid, {
  VibDataGridProps,
} from '@vibTheme/components/VibDataGrid';
import { Button } from '@vibTheme/components/_base/Button';
import CloseIcon from '@mui/icons-material/Close';
import {
  Groups,
  ParsedUserRole,
  UserProduct,
  UserRole,
} from '@interfaces/auth';
import { twMerge } from 'tailwind-merge';
import { styles } from './styles';
import useToastContext from '@contexts/ToastContext/hook';
import {
  SELECTED_GROUPS_LOCAL_STORAGE_PREFIX,
  getSelectedGroups,
  setSelectedGroups,
} from '@repository/selectedGroups';
import { useFirstRender } from '@hooks/useFirstRender';

export type InputProductProps = Omit<InputSelectProps, 'options' | 'type'> & {
  userRolesFilter: UserRole[];
  selectedLocalStorageId: string;
  onChangeCallback?: (ids: number[]) => void;
};

export const InputProduct = ({
  name,
  control,
  onChangeCallback,
  selectedLocalStorageId,
  userRolesFilter,
  ...restProps
}: InputProductProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { groups } = useGroupsContext();
  const { control: modalControl, watch } = useForm({
    defaultValues: { search: '' },
  });

  const allProducts = filterGroupsByUserRoles(groups, userRolesFilter);
  const selectedLocalStorageKey = `${SELECTED_GROUPS_LOCAL_STORAGE_PREFIX}-${selectedLocalStorageId}`;

  const selectedLocalStorage = getSelectedGroups(selectedLocalStorageKey);
  if (!selectedLocalStorage)
    setSelectedGroups(
      selectedLocalStorageKey,
      allProducts.map((group) => group.id),
    );

  const [products, setProducts] = useState<UserProduct[]>(allProducts);
  const [selectedProductIds, setSelectedProductIds] = useState<
    UserProduct['id'][]
  >(getSelectedGroups(selectedLocalStorageKey) || []);

  const handleClose = () => {
    setIsOpen(false);
  };

  const search = watch('search');
  useEffect(() => {
    if (!search) {
      setProducts(allProducts);
      return;
    }

    const results = allProducts.filter((product) =>
      product.name?.toLowerCase().includes(search.toLowerCase()),
    );
    setProducts(results);
  }, [search]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={selectedProductIds}
      render={({ field }) => {
        const toast = useToastContext();
        const triggerNoneSelectedToast = useCallback(
          (ids: UserProduct['id'][]) => {
            !ids.length &&
              toast.setContent(
                'Você deve selecionar pelo menos uma comunidade!',
                'warning',
              );
          },
          [],
        );

        useEffect(() => {
          triggerNoneSelectedToast(field.value);
          field.onChange(field.value);
        }, []);

        const selectedProducts = allProducts.filter((product) =>
          field.value.includes(product.id),
        );

        const onSelectionHandler: VibDataGridProps['onSelectionModelChange'] = (
          ids,
        ) => {
          const incomingIds = ids as number[];
          const currentIds = [...selectedProductIds, ...incomingIds];
          const uniqueIds = [...new Set(currentIds)];

          const removedIds = [] as number[];
          products.forEach(
            (product) =>
              !incomingIds.includes(product.id) && removedIds.push(product.id),
          );

          const newIds = uniqueIds.filter(
            (productId) => !removedIds.includes(productId),
          );
          setSelectedProductIds(newIds);
        };

        const onSubmit = (ids: UserProduct['id'][]) => {
          triggerNoneSelectedToast(ids);
          field.onChange(ids);
          onChangeCallback?.(ids);
          setSelectedGroups(selectedLocalStorageKey, ids);
          handleClose();
        };

        const isFirstRender = useFirstRender();
        useEffect(() => {
          if (isFirstRender) return;

          setProducts(allProducts);
          const ids = allProducts.map((group) => group.id);
          setSelectedProductIds(ids);
          onSubmit(ids);
        }, [JSON.stringify(userRolesFilter)]);

        return (
          <>
            <FormControl
              variant="outlined"
              className={twMerge(styles, restProps.className)}
              error={restProps.error}
            >
              <InputLabel htmlFor="products">Comunidades</InputLabel>
              <Select
                name="products"
                label="Comunidades"
                // O fakeValue é usado para forçar o label do Mui ao "estado ativo" (para a borda superior do input)
                value="fakeValue"
                displayEmpty
                open={false}
                MenuProps={{ disableScrollLock: true }}
                onClick={() => setIsOpen(true)}
                renderValue={() => {
                  return (
                    <div className="absolute flex items-center -translate-y-1/2 top-1/2 pr-sm max-w-[calc(100%-4.5rem)]">
                      {!!selectedProducts.length && (
                        <Chip
                          key={selectedProducts[0]?.id}
                          label={selectedProducts[0]?.name}
                        />
                      )}
                      {selectedProducts.length > 1 && (
                        <span className="text-sm ml-1">
                          +{selectedProducts.length - 1}
                        </span>
                      )}
                    </div>
                  );
                }}
              >
                <option value="fakeValue" />
              </Select>
              <FormHelperText>{restProps.helperText}</FormHelperText>
            </FormControl>

            <Modal open={isOpen} onClose={handleClose} className="flex p-main">
              <div className="flex flex-col bg-white rounded-default p-main mx-auto w-full sm:w-[640px]">
                <div className="relative">
                  <Typography component="h1">Comunidades</Typography>
                  <IconButton
                    className="absolute top-0 right-0 p-0"
                    onClick={handleClose}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
                <InputText
                  type="search"
                  name="search"
                  control={modalControl}
                  placeholder="Buscar"
                  InputProps={{
                    endAdornment: <SearchIcon className="text-[#6b7280]" />,
                  }}
                  className="my-main"
                />

                <div className="grow [&_.MuiGrid-root]:h-full">
                  <VibDataGrid
                    columns={columns}
                    rows={parseRows(products)}
                    checkboxSelection
                    onSelectionModelChange={onSelectionHandler}
                    selectionModel={selectedProductIds}
                    disableSelectionOnClick={false}
                    autoHeight={false}
                    className="h-full"
                    keepNonExistentRowsSelected
                  />
                </div>
                <Button
                  className="w-full mt-main"
                  onClick={() => onSubmit(selectedProductIds)}
                >
                  Adicionar
                </Button>
              </div>
            </Modal>
          </>
        );
      }}
    />
  );
};

const filterGroupsByUserRoles = (groups: Groups, userRoles: UserRole[]) => {
  const allProducts = [];

  if (userRoles.includes('OWNER')) allProducts.push(groups.owner);
  if (userRoles.includes('COPRODUCER')) allProducts.push(groups.coproducer);
  if (userRoles.includes('AFFILIATED')) allProducts.push(groups.affiliated);
  if (userRoles.includes('REFERRER')) allProducts.push(groups.referrer);

  return allProducts.flat();
};

const columns = [
  { field: 'name', headerName: 'Nome' },
  { field: 'userRole', headerName: 'Função de usuário' },
];

const parseRows = (rows: UserProduct[]) =>
  rows.map((userProduct) => ({
    ...userProduct,
    userRole: ParsedUserRole[userProduct.userRole],
  }));
